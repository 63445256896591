<template>
  <div>
    <div class="list-view product-checkout mt-0">
      <!-- Left Card -->
      <div class="hide-print">
        <numpad
          ref="numpadModal"
          @ok="cash = $event"
          @cancel="cash = $event"
        />
        <div>
          <h1>Opciones de pago</h1>
          <b-row
            class="match-height"
          >
            <b-col
              md="6"
              lg="3"
            >
              <b-card
                class="
                  payment-method
                  d-flex
                  justify-content-center
                  align-items-center
                "
                border-variant="primary"
                :bg-variant="paymentMethod != 'cash' ? null : 'primary'"
                @click="showModal()"
              >
                <h2>Efectivo</h2>
              </b-card>
            </b-col>
            <b-col
              md="6"
              lg="3"
            >
              <b-card
                class="
                  payment-method
                  d-flex
                  justify-content-center
                  align-items-center
                "
                border-variant="primary"
                :bg-variant="paymentMethod != 'bankcard' ? null : 'primary'"
                @click="paymentMethod = 'bankcard'"
              >
                <h2>Tarjeta bancaria</h2>
              </b-card>
            </b-col>
            <b-col
              md="6"
              lg="3"
            >
              <b-card
                v-if="isDeviceAndroid"
                class="
                  payment-method
                  d-flex
                  justify-content-center
                  align-items-center
                "
                border-variant="primary"
                :bg-variant="paymentMethod != 'chromeNFC' ? null : 'primary'"
                @click="paymentMethod = 'chromeNFC'"
              >
                <h2>Walleat Pay</h2>
              </b-card>
            </b-col>
            <b-col
              md="6"
              lg="3"
            >
              <b-card
                v-if="isDeviceAndroid"
                class="
                  payment-method
                  d-flex
                  justify-content-center
                  align-items-center
                "
                border-variant="primary"
                :bg-variant="paymentMethod != 'androidAppNfc' ? null : 'primary'"
                @click="paymentMethod = 'androidAppNfc'"
              >
                <h2>Walleat Pay (terminal externa)</h2>
              </b-card>
            </b-col>
            <b-col
              md="6"
              lg="3"
            >
              <b-card
                class="
                  payment-method
                  d-flex
                  justify-content-center
                  align-items-center
                "
                border-variant="danger"
                :bg-variant="paymentMethod != 'refund' ? null : 'danger'"
                @click="refundConfirmationModal()"
              >
                <h2>Devolución</h2>
              </b-card>
            </b-col>
          </b-row>

        </div>
      </div>

      <!-- Right Card -->
      <div class="amount-payable checkout-options hide-print">
        <b-card
          title="Detalles de la compra"
          class="border-primary"
        >
          <div class="price-details">
            <ul class="list-unstyled">
              <li class="price-detail">
                <div class="detail-title">
                  Productos
                </div>
                <div class="detail-amt discount-amt text-success">
                  {{ cartTotalProducts }}
                </div>
              </li>
              <li class="price-detail">
                <div class="detail-title">
                  Subtotal
                </div>
                <div class="detail-amt">
                  ${{ (cartTotal - cartTotal * 0.16) | money }} MXN
                </div>
              </li>
              <li class="price-detail">
                <div class="detail-title">
                  IVA
                </div>
                <div class="detail-amt">
                  ${{ (cartTotal * 0.16) | money }} MXN
                </div>
              </li>
            </ul>
            <hr>
            <ul class="list-unstyled">
              <li class="price-detail">
                <div class="detail-title detail-total">
                  Total
                </div>
                <div class="detail-amt font-weight-bolder">
                  ${{ cartTotal | money }} MXN
                </div>
              </li>
              <li
                v-if="paymentMethod === 'cash' && cash >= cartTotal"
                class="price-detail"
              >
                <div class="detail-title detail-total">
                  Cambio
                </div>
                <div
                  class="detail-amt font-weight-bolder"
                  :class="[cash - cartTotal > 1 ? 'text-primary' : 'text-danger']"
                >
                  ${{ cash - cartTotal| money }} MXN
                </div>
              </li>
            </ul>
            <b-button
              v-if="paymentMethod === 'cash' || paymentMethod === 'bankcard'"
              :variant="cash < cartTotal ? 'warning' : 'success'"
              block
              :disabled="cash < cartTotal"
              @click="completeSale()"
            >
              Continuar
            </b-button>
            <android-nfc-chrome
              v-if="paymentMethod === 'chromeNFC'"
              @prev-step="prevStep()"
            />
            <nfc-android-app
              v-if="paymentMethod === 'androidAppNfc'"
              @prev-step="prevStep()"
            />
          </div>

        </b-card>
      </div>
    </div>

    <!-- TICKET ✌️🐔 -->
    <div
      v-if="cart[0]"
      class="ticket checkout-options pb-1"
    >
      <div class="d-flex justify-content-center p-1">
        <b-img
          :src="require('@/assets/images/logo/walleat_logo.svg')"
          alt="Medal Pic"
          width="100"
        />
      </div>
      <div class="text-center font-weight-bolder pb-1 h6 text-black">
        {{ cart[0].store_attributes.name }}
      </div>
      <div class="price-details">
        <ul class="list-unstyled border-bottom">
          <li class="mb-1 price-detail">
            <div class="detail-title">
              Fecha:
            </div>
            <div class="detail-amt">
              {{ Date.now() | dateNtime }}
            </div>
          </li>
        </ul>
      </div>
      <div class="price-details">
        <ul class="list-unstyled border-bottom">
          <li
            v-for="product in cart"
            :key="`product-ticket-${product.id}`"
            class="mb-1 price-detail"
          >
            <div class="detail-title">
              {{ product.units }} x {{ product.product_attributes.name }}
            </div>
            <div class="detail-amt">
              ${{ product.unit_cost * product.units | money }}
            </div>
          </li>
        </ul>
        <ul class="list-unstyled">
          <li class="price-detail">
            <div class="detail-title">
              Productos
            </div>
            <div class="detail-amt discount-amt">
              {{ cartTotalProducts }}
            </div>
          </li>
          <li class="price-detail">
            <div class="detail-title">
              Subtotal
            </div>
            <div class="detail-amt">
              ${{ (cartTotal - cartTotal * 0.16) | money }}
            </div>
          </li>
          <li class="price-detail">
            <div class="detail-title">
              IVA
            </div>
            <div class="detail-amt">
              ${{ (cartTotal * 0.16) | money }}
            </div>
          </li>
        </ul>
        <hr>
        <ul class="list-unstyled">
          <li class="price-detail">
            <div class="detail-title detail-total">
              Total
            </div>
            <div class="detail-amt font-weight-bolder">
              ${{ cartTotal | money }}
            </div>
          </li>
          <li
            v-if="paymentMethod === 'cash' && cash >= cartTotal"
            class="price-detail"
          >
            <div class="detail-title detail-total">
              Cambio
            </div>
            <div
              class="detail-amt font-weight-bolder"
            >
              ${{ cash - cartTotal| money }} MXN
            </div>
          </li>
        </ul>
        <div class="text-center">
          <qriously
            value="https://mywalleat.com"
            :size="80"
          />
        </div>
        <div class="text-center">
          https://mywalleat.com
        </div>
        <div class="text-center text-black h6 py-1">
          Gracias ✌️
        </div>
      </div>
      <hr class="mt-5">
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { VMoney } from 'v-money'
// import Cleave from 'vue-cleave-component'
import {
  BCard,
  // BFormGroup,
  // BFormRadio,
  // BFormInput,
  // BInputGroupPrepend,
  // BInputGroupAppend,
  // BInputGroup,
  BImg,
  BRow,
  BCol,
  // BCardText
  BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Numpad from '@core/components/NumPad.vue'
import AndroidNfcChrome from './AndroidNfcChrome.vue'
import NfcAndroidApp from './NfcAndroidApp.vue'

export default {
  components: {
    Numpad,
    BRow,
    BCol,
    // BSV
    BCard,
    // BCardText,
    BImg,
    // BFormGroup,
    // BFormRadio,
    BButton,
    // BFormInput,
    // BInputGroupPrepend,
    // BInputGroupAppend,
    // BInputGroup,
    AndroidNfcChrome,
    NfcAndroidApp,
  },
  directives: {
    Ripple,
    money: VMoney,
  },
  props: {
    // paymentDetails: {
    //   type: Object,
    //   required: true,
    // },
  },
  data() {
    return {
      numValue: '',
      showCashModal: false,
      paymentMethod: 'cash',
      cash: 0,
      isDeviceAndroid: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '$ ',
        suffix: ' MXN',
        precision: 0,
      },
    }
  },
  computed: {
    ...mapGetters('pos', [
      'cartTotal',
      'cartTotalProducts',
      'cart',
    ]),
  },
  watch: {
    paymentMethod(newValue) {
      if (newValue === 'bankcard') {
        this.cash = this.cartTotal
      }
    },
  },
  mounted() {
    const ua = navigator.userAgent.toLowerCase()
    const isAndroid = ua.indexOf('android') > -1
    // && ua.indexOf('mobile')
    if (isAndroid) {
      this.isDeviceAndroid = true
    }
  },
  methods: {
    ...mapActions('orders', [
      'addOrder',
    ]),
    ...mapActions('pos', [
      'emptyCart',
    ]),
    ...mapActions('printer', ['print']),
    prevStep() {
      this.$emit('prev-step')
    },
    showModal() {
      this.paymentMethod = 'cash'
      this.$refs.numpadModal.show()
    },
    refundConfirmationModal() {
      this.paymentMethod = 'refund'
      this.$bvModal.msgBoxConfirm('Por favor confirma que deseas realizar el reembolso.', {
        title: 'Reembolso',
        size: 'sm',
        okVariant: 'danger',
        okTitle: 'SÍ',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value === true) {
            const tempCart = []
            this.cart.forEach(product => {
              const refactorProduct = {
                store_product_id: product.id,
                units: product.units,
              }
              tempCart.push(refactorProduct)
            })
            const orderReady = {
              store_id: this.$route.params.store_id,
              order_store_products_attributes: tempCart,
              order_payments_attributes: [
                {
                  payment_type: 'cash',
                  amount: this.cartTotal
                },
              ],
            }
            this.addOrder({ order: orderReady, orderType: 'sell', isRefund: 'true' })
              .then(() => {
                if (JSON.parse(localStorage.getItem('print')) === true) {
                  // window.print()

                  const order = {
                    store: this.cart[0].store_attributes.name,
                    products: [],
                    subtotal: this.cartTotal - this.cartTotal * 0.16,
                    iva: this.cartTotal * 0.16,
                    total: this.cartTotal,
                    date: Date.now(),

                  }

                  this.cart.forEach(product => {
                    order.products.push({
                      name: product.product_attributes.name,
                      variant: product.product_attributes.variant,
                      units: product.units,
                      unit_price: product.unit_price,
                    })
                  })

                  this.print({ ...order })
                }
                this.bracelet_id = null
                // eslint-disable-next-line
                const audio = new Audio(require('@/assets/sounds/Success.wav'))
                audio.play()
                this.$swal({
                  title: 'Reembolso exitoso!',
                  text: 'Gracias.',
                  icon: 'success',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                this.cash = null
                this.emptyCart()
                this.prevStep()
              }).catch(error => {
                this.bannedItems = error.response.data.banned_items
                if (error.response.data.messages[0]) {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Error',
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: error.response.data.messages[0],
                    },
                  })
                }
              })
          }
        })
        .catch(err => {
          // eslint-disable-next-line
          console.log(err)
          // An error occurred
        })
    },
    getPaymentType(method) {
      if (method === 'cash') {
        return 'cash'
      }
      if (method === 'refund') {
        return 'refund'
      }
      if (method === 'chromeNFC' || method === 'androidAppNfc') {
        return 'walleat'
      }
      if (method === 'bankcard') {
        return 'bankcard'
      }
      return '😭'
    },
    completeSale() {
      this.numValue = ''
      const tempCart = []
      this.cart.forEach(product => {
        const refactorProduct = {
          store_product_id: product.id,
          units: product.units,
        }
        tempCart.push(refactorProduct)
      })
      const orderReady = {
        store_id: this.$route.params.store_id,
        order_store_products_attributes: tempCart,
        order_payments_attributes: [
          {
            payment_type: this.getPaymentType(this.paymentMethod),
            amount: this.cartTotal
          },
        ],
      }
      this.addOrder({ order: orderReady, orderType: 'sell' })
        .then(() => {
          if (JSON.parse(localStorage.getItem('print')) === true) {
            // window.print()

            const order = {
              order_type: {
                payment: this.getPaymentType(this.paymentMethod),
                type: 'sell',
              },
              store: this.cart[0].store_attributes.name,
              products: [],
              subtotal: this.cartTotal - this.cartTotal * 0.16,
              iva: this.cartTotal * 0.16,
              total: this.cartTotal,
              date: Date.now(),

            }

            this.cart.forEach(product => {
              order.products.push({
                name: product.product_attributes.name,
                variant: product.product_attributes.variant,
                units: product.units,
                unit_price: product.unit_price,
              })
            })

            this.print({ ...order })
          }
          this.bracelet_id = null
          // eslint-disable-next-line
          const audio = new Audio(require('@/assets/sounds/Success.wav'))
          audio.play()
          this.$swal({
            title: 'Cobro exitoso!',
            text: 'Gracias.',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.cash = null
          this.emptyCart()
          this.prevStep()
        }).catch(error => {
          this.bannedItems = error.response.data.banned_items
          if (error.response.data.messages[0]) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: error.response.data.messages[0],
              },
            })
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
#cvv {
  width: auto;
}
</style>

<style lang="scss">
.ticket {
  display: none;
}

@media print {
  .ticket {
    display: block;
    color: black;
    font-size: 10px;

    .h4, .h6 {
      color: black;
    }

  }
  .hide-print {
    display: none !important;
  }
  body {
    background-color: transparent !important;
    min-width: 100% !important;
  }
  .main-menu,
  .footer,
  .header-navbar-shadow,
  .wizard-nav,
  nav.header-navbar {
    display: none !important;
  }
  #app > div > div.app-content.content.ecommerce-application,
  body .app-content {
    margin: 0 !important;
    padding: 0 !important;
  }
}

.payment-method {
  min-height: 150px;
}
</style>
